@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

body {
  font-family: 'Inter';
}
